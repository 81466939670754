import React from "react"
import { useMediaQuery } from "react-responsive"
import Layout from "../components/layout"
import CollabsRow from "../components/CollabsRow"
import AboutMe from "../components/AboutMe"
import InView from "../components/InView"
import LazyImage from "react-lazy-blur-image"
import mainImageMobileHosted from "../assets/mainImageMobile.jpg"
import useDeviceDetect from "../utils/useDeviceDetect.js"
import { bpTablet, bpMobile } from "../layouts/styles/index.scss"

function Arrow(props) {
  const { style, onClick, type } = props
  return (
    <div
      onClick={onClick}
      role="button"
      style={{
        ...style,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        ...(type !== "next" ? { left: "-33px" } : { right: "-25px" }),
        color: "lightgrey",
        zIndex: "20",
        cursor: "pointer",
      }}
    >
      <i
        style={{ fontSize: "25px" }}
        className={
          type !== "next" ? "icon-left-open-big" : "icon-right-open-big"
        }
      ></i>
    </div>
  )
}

const Home = ({
  pageContext: {
    hotelsCollabs,
    restaurantsCollabs,
    aboutMeImage,
    aboutMeText,
    mainImage,
    mainImageMobile,
    jobTitle,
  },
}) => {
  const { isMobileSSR } = useDeviceDetect()
  const isTablet = useMediaQuery({ query: `(max-width: ${bpTablet})` })
  const isMobile = useMediaQuery({ query: `(max-width: ${bpMobile})` })

  const aboutMe = aboutMeText[0].fields.aboutMeText

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
    slidesToScroll: 1,
    arrows: isMobile ? false : true,
    pauseOnHover: true,
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="back" />,
  }

  return (
    <div id="home">
      <Layout
        restaurantsCollabs={restaurantsCollabs}
        hotelsCollabs={hotelsCollabs}
        isMobile={isMobile || isMobileSSR}
      >
        <div className="hero">
          {isMobileSSR !== null && !isMobileSSR && (
            <img
              alt="Hero"
              className="hero__overlay__image"
              src={
                "https:" +
                mainImage[0].fields.mainImage.fields.file.url +
                "?w=850"
              }
            />
          )}
          {isMobileSSR !== null && isMobileSSR && (
            <LazyImage
              placeholder={mainImageMobileHosted}
              uri={
                "https:" +
                mainImageMobile[0].fields.mainImageMobile.fields.file.url +
                "?h=500&fl=progressive&q=70"
              }
              render={src => (
                <img
                  src={src}
                  alt="Hero"
                  style={{
                    height: "100vh",
                    width: "100vw",
                    objectFit: "cover",
                    filter: "blur(1px)",
                    maxWidth: "100%",
                    overflow: "hidden",
                  }}
                />
              )}
            />
          )}
          <div className="hero__text">
            <hr className="hero__text__separator" />
            <h1 className="hero__text__headline">
              {jobTitle[0].fields.jobTitle}
            </h1>
            <div className="hero__text__subline">CAROLINA NETO</div>
          </div>
          <div className="box">
            <span></span>
          </div>
        </div>
        <InView threshold={0.9} tag="div">
          <CollabsRow
            type="Restaurants"
            collabs={restaurantsCollabs}
            sliderSettings={sliderSettings}
            id="hero-container"
          />
        </InView>
        <InView tag="div">
          <CollabsRow
            type="Hotels"
            collabs={hotelsCollabs}
            sliderSettings={sliderSettings}
            theme="dark"
          ></CollabsRow>
        </InView>
        <div id="about-me__anchor"></div>
        <AboutMe
          id="about-me"
          aboutMeImage={aboutMeImage}
          aboutMe={aboutMe}
        ></AboutMe>
      </Layout>
    </div>
  )
}

export default Home
