import React from "react"
import InView from "../components/InView"
const AboutMe = ({ aboutMeImage = "", aboutMe = {}, id = "" }) => {
  return (
    <div className="row-hero" id={id}>
      <div className="about-me">
        <div className="about-me__image">
          <InView>
            <img
              id="about-me__image"
              loading="lazy"
              alt="About me"
              src={
                "https:" +
                aboutMeImage[0].fields.aboutMeImage.fields.file.url +
                "?h=500&q=75"
              }
            />
          </InView>
        </div>
        <div className="about-me__text">
          <h4 className="row-hero__headline">About me</h4>
          <h4 className="row-hero__subline">Carolina Neto</h4>
          <hr className="row-hero__separator" />
          <div className="about-me__info">
            {aboutMe &&
              aboutMe.content.map(textElement => (
                <p
                  key={textElement.content[0].value}
                  className="partnership__text"
                >
                  {textElement.content[0].value}
                </p>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutMe
