import React from "react"
import PropTypes from "prop-types"
import { useInView } from "react-intersection-observer"

const InView = ({
  children,
  tag: Tag,
  enforceOnSsr,
  threshold = 0,
  ...props
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold })
  return (
    <Tag ref={ref} {...props}>
      {inView || (enforceOnSsr && typeof window === "undefined") ? (
        children
      ) : (
        <div
          style={{ width: "1px", height: "1px", background: "transparent" }}
        />
      )}
    </Tag>
  )
}

InView.propTypes = {
  enforceOnSsr: PropTypes.bool,
  tag: PropTypes.oneOf(["div", "picture"]),
  children: PropTypes.node.isRequired,
}

InView.defaultProps = {
  tag: "div",
}

export default InView
