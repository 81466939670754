import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

const CollabsRow = ({
  id = "",
  collabs = [],
  type = "",
  sliderSettings = {},
  theme = "light",
}) => {
  return (
    <div
      id={id}
      className={theme === "dark" ? "row-hero row-hero--dark" : "row-hero"}
    >
      <h3 className="row-hero__headline">Latest Collabs</h3>
      <h4 className="row-hero__subline">{type}</h4>
      <hr className="row-hero__separator" />
      <div className="slider-wrapper">
        <Slider {...sliderSettings}>
          {collabs.length > 0 &&
            collabs.map(collab => (
              <Link
                className="collab"
                to={`/partnerships/${collab.fields.name
                  .replace(/[^a-zA-Z]/g, "-")
                  .toLowerCase()}`}
                key={collab.fields.name
                  .replace(/[^a-zA-Z]/g, "-")
                  .toLowerCase()}
              >
                <img
                  className="collab__image"
                  alt="Collaboration on homepage"
                  src={
                    "https:" +
                    collab.fields.image.fields.file.url +
                    "?h=650&q=75"
                  }
                ></img>
                <div className="collab__hover-image">
                  <div className="collab__hover-text">
                    <div className="row-hero__headline">{collab.fields.name}</div>
                  </div>
                </div>
              </Link>
            ))}
        </Slider>
      </div>
    </div>
  )
}

export default CollabsRow
